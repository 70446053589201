































































































import { Component, Vue } from "vue-property-decorator";
import {
  ApiGetCategoryList,
  ApiGetDoctorScaleList,
  ApiGetPatientInfoRecord,
} from "@/apis";
import { parseTime } from "@/utils";
import { ScaleCategory, ScaleDetail } from "@/types";
import { RouteConfig } from "vue-router";
Component.registerHooks(["beforeRouteEnter"]);
@Component({
  components: {},
  filters: {
    parseTimeFilter(time: any, str: string) {
      if (time) {
        return parseTime(time, str);
      } else {
        return "-";
      }
    },
  },
})
export default class ScaleEvaluation extends Vue {
  scaleData = [] as Array<ScaleCategory>;
  defaultProps = {
    children: "children",
    label: "label",
  };
  formList: any = {
    evaluate_name: "",
    evaluate_start: "",
  };
  scaleLists = [];
  //表格分页器变量
  pagination = {
    //分页器当前页显示几条数据
    size: 10,
    //表格的数据总量
    total: 0,
    //当前是第几页
    page: 1,
  };
  databaseId = "";
  scaleId = 0;
  module = 212;
  totalSize = 0;
  //当前所在tree结构节点：
  current_key = 3;
  //从前一页进入本页的前一页路径
  path = "";
  beforeRouteEnter(to: RouteConfig, from: any, next: Function) {
    next((vm: any) => {
      // 进入组件之前未被实例化，无法直接访问this
      // 通过 `vm` 访问组件实例
      vm.path = from.fullPath;
    });
  }
  created() {
    this.getCategoryList();
    this.databaseId = sessionStorage.getItem("case_databaseId") as string;
  }
  mounted() {
    this.init();
  }
  init() {
    if (this.path === "/doctor-system/medical/list") {
      this.current_key = 3;
    } else {
      this.scaleId = sessionStorage.getItem("case_scaleId")
        ? parseInt(sessionStorage.getItem("case_scaleId") as any)
        : this.scaleId;
      this.module = sessionStorage.getItem("case_moduleId")
        ? parseInt(sessionStorage.getItem("case_moduleId") as any)
        : this.module;
      this.current_key = this.scaleId;
    }
    this.changeKey(this.current_key);
    this.getPatientRecord();
  }
  changeKey(key: number) {
    const tree: any = this.$refs["tree"];
    const timer = setTimeout(() => {
      tree.setCurrentKey(this.current_key);
      clearTimeout(timer);
    }, 500);
  }
  getCategoryList() {
    // 获取量表类别;
    ApiGetCategoryList().then((res) => {
      if (res.success) {
        const resp = res.data;
        resp.forEach((item: any) => {
          this.scaleData.push({
            id: item.id,
            label: item.name,
            children: [],
          });
        });
        // 获取每一个分类下的具体量表; 默认展开第一个;
        this.getScaleList(this.scaleData[0].id);
      }
    });
  }
  getScaleList(id: number) {
    const params = {
      category_id: id,
    };
    ApiGetDoctorScaleList(params).then((resp) => {
      const result = resp.data;
      result.forEach((items: any) => {
        this.scaleData[0].children.push({
          id: items.id,
          label: items.name,
          module: items.module,
          category_id: items.category_id,
        });
      });
    });
  }
  getPatientRecord() {
    let date: any = this.formList.evaluate_start
      ? this.formList.evaluate_start + " 00:00:00"
      : "";
    if (date != "") {
      date = new Date(date).getTime();
    }
    const params = {
      database_id: this.databaseId,
      category_detail_id: this.scaleId || 3,
      //分页查询：当前页、页数据量
      cur_page: this.pagination.page,
      page_size: this.pagination.size,
      //查询条件
      evaluate_name: this.formList.evaluate_name,
      evaluate_start: date,
    };
    ApiGetPatientInfoRecord(params).then((res) => {
      if (res.success) {
        this.scaleLists = res.data;
        this.pagination.total = res.page.total_size;
      }
    });
  }
  searchButton() {
    this.getPatientRecord();
  }
  handleNodeClick(data: ScaleDetail) {
    const tree: any = this.$refs["tree"];
    this.scaleId = data.id;
    this.module = data.module;
    this.getPatientRecord();
    sessionStorage.setItem("case_scaleId", this.scaleId.toString());
  }
  handleSizeChange(val: number) {
    this.pagination.size = val;
    this.getPatientRecord();
  }
  handleCurrentChange(val: number) {
    this.pagination.page = val;
    this.getPatientRecord();
  }
  //method
  addButton() {
    //跳转到添加量表页
    this.$router.push({
      name: "evaluationadd",
    });
    sessionStorage.setItem("case_moduleId", this.module.toString());
  }
  viewDetail(v: any) {
    //跳转到量表查看页;
    this.$router.push({
      name: "evaluationdetail",
      params: {
        id: v,
      },
    });
    sessionStorage.setItem("case_moduleId", this.module.toString());
  }
}
